import React, { useEffect, useState } from "react";
// import headBanner from "../../../assets/images/backgrounds/register.png";
import headBanner from "../../../assets/images/headerImages/signup.jpg";
import productbg from "../../../assets/images/productbg.jpg";
import arrowRight from "../../../assets/images/arrow-narrow-right.png";
import { Button, Input } from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import OwlCarousel from "react-owl-carousel2";
import styled from "styled-components";
import { LineArrow } from "../../SvgComponents";

export default function RegisterBanner(props) {
  const { backgroundImage = headBanner, sliderImg, productStore } = props;
  const [height, setHeight] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const history = useHistory();
  const options = {
    nav: true,
    loop: true,
    autoplay: true,
    autoplayTimeout: 1000,

    lazyLoad: true,
    animateOut: "fadeOut",

    dots: false,
    nav: false,
    items: 1,
  };

  useEffect(() => {
    const header = document.getElementById("header");
    //  const headerHeight = header.offsetHeight
    window.addEventListener("resize", setHeight(header?.offsetHeight));
    console.log("header" + height);
  }, [height]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") handleRedirect();
  };

  const handleRedirect = () => {
    if (searchInput.length >= 3)
      history.push({
        pathname: "/stores/gsharshop/products",
        state: { searchInput },
      });
  };

  const Slider = styled.div`
    display: flex;
    // margin-top: 60px;
    .owl-stage {
      display: flex;
      height: calc(550px + ${height}px);
    }
    .owl-dots {
      position: absolute;
      left: 0;
      top: 90%;
    }
    .owl-prev {
      position: absolute;
      top: 40%;
      left: 0%;
      display: block !important;
    }
    .owl-next {
      position: absolute;
      top: 40%;
      right: 0%;
      display: block !important;
    }
    img {
      height: 100%;
      object-fit: cover;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
  `;
  return (
    <>
      {productStore ? (
        <div>
          <Slider className="registerBanner">
            <OwlCarousel options={options}>
              {sliderImg ? (
                Object.keys(sliderImg).map((img, key) => (
                  <img
                    key={key}
                    style={{
                      filter: "brightness(50%)",
                      backgroundSize: "cover",
                      backgroundPositionY: "center",
                      backgroundColor: "gray",
                    }}
                    alt={img}
                    src={`https://bo.discovershurooq.ae/files/${sliderImg[img]}`}
                  />
                ))
              ) : (
                <div
                  style={{
                    filter: "brightness(50%)",
                    backgroundColor: "gray",
                    width: "100%",
                    height: "100%",
                  }}
                />
              )}
            </OwlCarousel>
          </Slider>

          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "10%",
              zIndex: "9",
              color: "#FFFFFF",
              lineHeight: "1.5",
              cursor: "default",
              transform: "translateY(-50%)",
            }}
          >
            <div
              style={{
                backgroundColor: "#202434",
                padding: "10px 20px",
                display: "inline",
                borderRadius: "5px",
                fontSize: "14px",
              }}
            >
              UAE Artists and Designers
            </div>
            <h1 className="bannerDesc">
              The heart of handmade gifts from around the region
            </h1>
            <div
              className="search-destination"
              style={{ maxWidth: "500px", marginRight: "2rem" }}
            >
              <div className="wrapper">
                <div className="inp-wrapper">
                  <div className="inps">
                    <div className="">
                      <Input
                        className="keywords"
                        placeholder="Search for products"
                        value={searchInput}
                        onKeyDown={handleKeyDown}
                        onChange={(e) => setSearchInput(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="btn-wrapper">
                    <Button
                      style={{
                        backgroundColor: "#F89828",
                        // marginTop: "70px",
                        color: "#FFFFFF",
                        fontSize: 15,
                        fontWeight: 600,
                        border: "none",
                        borderRadius: "7px",
                        width: "100%",
                        // padding: "17px 28px",
                      }}
                      onClick={handleRedirect}
                      // className="arrow"
                    >
                      <span style={{ whiteSpace: "nowrap" }}>
                        View Products
                        {/* <img src={arrowRight} style={{ marginLeft: ".5rem" }} /> */}
                        {/* <LineArrow /> */}
                      </span>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            {/* <Link to={`/stores/gsharshop/products`}>
              <Button
                style={{
                  backgroundColor: "#F89828",
                  marginTop: "70px",
                  color: "#FFFFFF",
                  fontSize: 15,
                  fontWeight: 600,
                  border: "none",
                  borderRadius: "7px",
                  padding: "17px 28px",
                }}
                className="arrow"
              >
                <span>
                  View Products
                  <LineArrow />
                </span>
              </Button>
            </Link> */}
          </div>
        </div>
      ) : (
        <section
          className="register-banner"
          style={{ backgroundImage: `url(${backgroundImage})` }}
        ></section>
      )}
    </>
  );
}
