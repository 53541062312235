import React, { useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import RegisterBanner from "../../components/banners/RegisterBanner";
import axios from "axios";
import ProductList from "../../components/productList";
import OwlCarousel from "react-owl-carousel2";
import "./style.scss";
import StoreMenu from "../../components/layout/header/store-menu";
import globalConfig from "../../globalConfig";

const options = {
  nav: false,
  loop: false,
  autoplay: false,
  navText: [
    '<img src="https://img.icons8.com/ios/48/ffffff/circled-chevron-left.png"/>',
    '<img src="https://img.icons8.com/ios/48/ffffff/circled-chevron-right.png"/>',
  ],
  responsive: {
    0: {
      items: 1,
    },
    576: {
      items: 2,
    },
    767: {
      items: 3,
    },
    991: {
      items: 4,
    },
    1200: {
      items: 6,
    },
    // 1600: {
    //   items: 5,
    // },
  },
};

const Store = (props) => {
  globalConfig.isStorePage = true;
  const [destinations, setDestinations] = useState([]);
  const [products, setProducts] = useState([]);

  const history = useHistory();
  localStorage.setItem("pathname", history.location.pathname);

  useEffect(() => {
    const bodyFormData = new FormData();
    bodyFormData.append("action", "storeData");
    axios.post(process.env.REACT_APP_BASE_URL, bodyFormData).then((res) => {
      if (res.data.data) {
        setDestinations(res.data.data.destinations);
        setProducts(res.data.data.products);
      }
    });
  }, []);

  return (
    <div>
      {/* <RegisterBanner /> */}
      <div
        className="py-5 h-100"
        // style={{ marginTop: "3rem" }}
      >
        <StoreMenu />
        <div
          className="d-flex container justify-content-between align-items-center mb-5 mt-5"
          // style={{ marginTop: "5rem" }}
        >
          <h2 className="sec-title">Stores</h2>
        </div>

        {destinations.map((destination) => (
          <div key={destination.id} className="mb-4">
            <h2 className="destination container mb-3">
              <a href={`stores/${destination.url_slug}`}>
                {destination.name +
                  `(${
                    products.filter(
                      (product) => product.vendors === destination.id
                    ).length
                  })`}
              </a>
            </h2>

            <div className="products">
              {products.filter((item) => item.vendors === destination.id)
                .length > 0 ? (
                <OwlCarousel options={options}>
                  {products
                    .filter((item) => item.vendors === destination.id)
                    .map(
                      (product, index) =>
                        Number(product.qty) > 0 && (
                          <div
                            key={product.product_id}
                            className={`product-item ${
                              index == 0 ? "product-item-first" : ""
                            }`}
                          >
                            <ProductList product={product}></ProductList>
                          </div>
                        )
                    )}
                </OwlCarousel>
              ) : (
                <h5 className="container">No Products for this Destination</h5>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default connect()(withRouter(Store));
